import React, { useState } from 'react'

type Props = {
  title: string
  body: string
  open: boolean
  onClick: () => void
  index: number
}

export const Accordion = ({ title, body, open, onClick, index }: Props) => {
  return (
    <>
      <h2 id={`accordion-flush-heading-${index}`} onClick={onClick}>
        <button
          type="button"
          className={`flex w-full items-center justify-between py-5 text-left font-medium text-gray-${
            open ? '900' : '500'
          } border-b border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900 dark:text-${
            open ? 'white' : 'gray-400'
          }`}
          data-accordion-target={`accordion-flush-body-${index}`}
          aria-expanded="true"
          aria-controls={`accordion-flush-body-${index}`}
        >
          <span>{title}</span>
          <svg
            data-accordion-icon=""
            className={`h-6 w-6 rotate-${open ? '180' : 0} shrink-0`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        data-testid="accordion-body"
        id={`accordion-flush-body-${index}`}
        className={open ? '' : 'hidden'}
        aria-labelledby={`accordion-flush-heading-${index}`}
      >
        <div className="border-b border-gray-200 py-5 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">{body}</p>
        </div>
      </div>
    </>
  )
}

const Faq = ({ questions = [] }: { questions: Record<'title' | 'body', string>[] }) => {
  const [open, setOpen] = useState<number>(0)
  return (
    <section className="relative z-20 bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6 ">
        <h2 className="mb-6 text-center text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8">
          Frequently asked questions
        </h2>
        <div className="mx-auto max-w-screen-md">
          <div
            id="accordion-flush"
            data-accordion="collapse"
            data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
            data-inactive-classes="text-gray-500 dark:text-gray-400"
          >
            {questions.map(({ title, body }, index) => (
              <Accordion
                title={title}
                body={body}
                open={open === index}
                onClick={() => setOpen(open === index ? -1 : index)}
                key={index}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
